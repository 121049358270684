import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  StyledEngineProvider,
} from '@mui/joy';
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import { SpeedInsights } from '@vercel/speed-insights/react';
import Landing from 'Landing';
import Resume from 'Resume';
import ReactGA from 'react-ga4';
import 'App.global.scss';
import Projects from 'components/projects/Projects';
import ThemeLoader from 'components/ThemeLoader';
import NavigationBar from 'NavigationBar';
import Copyright from 'components/Copyright';
import SiteMap from 'Sitemap';
import Maintainence from 'Maintainence';

ReactGA.initialize('G-75XL7GN77C');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <div> <Maintainence /> </div>
  </React.StrictMode>,
);
