import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { BsJournalCode } from 'react-icons/bs';

const FullPageContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  background: 'linear-gradient(180deg, #364fc7 -100%, #000000)',
  color: '#fff',
  zIndex: 9999,
  textAlign: 'center',
});

const IconWrapper = styled('div')({
  position: 'relative',
  marginTop: '20px',
});

const PulsatingRing = styled('div')({
  position: 'absolute',
  top: 'calc(2px)', // Adjusted to center vertically
  left: 'calc(-25px)', // Adjusted to center horizontally
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  border: '3px solid rgba(255, 255, 255, 0.5)',
  animation: 'pulse 1.4s ease-out infinite',
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.5)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0,
    },
  },
});

export default function MaintenancePage() {
  return (
    <FullPageContainer>
      <Typography variant="h4" component="p">
        Temporarily Down
      </Typography>
      <Typography variant="h6" component="p" sx={{ marginTop: '10px' }}>
        Experiencing some proxy issues. Will be back up soon :)
      </Typography>
      <IconWrapper>
        <PulsatingRing />
      </IconWrapper>
    </FullPageContainer>
  );
}
